import React from "react"
import SidebarBox from "../components/sidebar-box";
import WidgetContactEmail from "../components/widget-contact-email";
import WidgetContactPhone from "../components/widget-contact-phone";

const Sidebar = ({ data }) => (
    <aside id="sidebar" className="w-full bg-grey-lightest px-8 pt-8 lg:pt-16 lg:-mt-16 xl:p-16 xxl:pt-16 xxl:p-8 lg:w-1/3">
        <h3 className="mb-6">Våra tjänster</h3>
        {data.edges
        .map(block => <SidebarBox key={block.node.id} block={block.node.frontmatter} />)}

        <h3 className="mb-6">Kontakta oss</h3>
        <section className="xxl:flex">
            <WidgetContactEmail />
            <WidgetContactPhone />
        </section>
    </aside>
)

export default Sidebar