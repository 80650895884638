import React from "react"
import { Link } from "gatsby"

const PostLink = ({ block }) => (
  <section className="bg-white mb-8 p-6">
    <h5 className="text-sm mb-2 hyphens">{block.name}</h5>
    <p className="text-xs">{block.service_excerpt}</p>
    <Link
      to={block.slug}
      className="link text-orange text-xs hover:text-orange-dark transition"
    >
      Läs mer
    </Link>
  </section>
)

export default PostLink